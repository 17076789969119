import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"

import Section from "../components/section"

const PrivacyPolicyPage = props => (
  <Layout>
    <SEO title="Privacy Policy" />

    <BackgroundImage
      fluid={props.data.privacyPolicyImage.childImageSharp.fluid}
      img="Man on laptop"
    >
      <Header headerText="Privacy Policy"></Header>
    </BackgroundImage>
    <Section>
      <p>
        At <strong>Cutter &amp; Co Financial Planning Ltd</strong>, we're
        committed to protecting and respecting your privacy.{" "}
        <strong>
          By using our website, you're agreeing to be bound by this Policy
        </strong>
        .
      </p>

      <p>
        This Privacy Policy explains when and why we collect personal
        information about people who visit our website, how we use it, the
        conditions under which we may disclose it to others and how we keep it
        secure.
      </p>

      <p>
        We may change this Policy from time to time so please check this page
        occasionally to ensure that you're happy with any changes.
      </p>

      <p>
        <strong>Cutter &amp; Co Financial Planning Ltd</strong> is an appointed
        representative of Navigator Financial Planning Ltd which is authorised
        and regulated by the Financial Conduct Authority (FCA). FCA number
        805876.
      </p>

      <p>
        Cutter &amp; Co Financial Planning Ltd and Navigator Financial Planning
        Ltd are part of the True Wealth Group (TWG), a group of companies of
        which the parent company is True Wealth Planning Solutions Ltd (TWPS).
        The True Wealth Group provides access to regulatory and non-regulatory
        specialist services within the Financial Services sector.
        <strong> Cutter &amp; Co Financial Planning Ltd</strong> acts as a
        controller for the personal information you provide.
      </p>

      <p>
        Any questions regarding this Privacy Notice and our privacy practices
        should be sent by e-mail to datacontroller@truewg.com or to The Data
        Controller, <strong>Cutter &amp; Financial Planning Ltd</strong>,
        Stonyroyd House, 8 Cumberland Road, Leeds, West Yorkshire, LS6 2EF.
        Alternatively, you can telephone 0113 224 2800.
      </p>

      <h2 id="howdowecollectinformationfromyou">
        How do we collect information from you?
      </h2>

      <p>
        We obtain information about you when you seek our advice or other
        services.
      </p>

      <h2 id="whattypeofinformationiscollectedfromyou">
        What type of information is collected from you?
      </h2>

      <p>
        If you are visiting our website the personal information we collect
        might include your name, address, email address, IP address, and
        information regarding what pages are accessed and when.
      </p>

      <p>
        If you are seeking advice or other services from us we will undertake a
        getting to know you process.
      </p>

      <p>
        In order to provide financial planning services to you in accordance
        with this agreement, this may include but is not limited to giving you
        financial advice and making recommendations as to investments and
        financial products which are suitable for you. We do so by taking into
        account current financial markets and economic conditions, availability
        of products and the providers of those products, as well as a detailed
        analysis of your personal circumstances and financial requirements and
        objectives.
      </p>

      <p>
        Where you have investments or polices we will obtain information on
        these either from you or upon agreement from you directly from the
        providers concerned. We will assess your Attitude to Risk and Capacity
        for loss and record this in our documentation. We will retain records of
        any investments or policies that you arrange through us.
      </p>

      <p>
        Where we are providing an ongoing service we will update the information
        as part of our review process and note our records.
      </p>

      <p>
        Information may be obtained from you face to face, via post, through
        e-mail or completion of online documentation. As the information is
        required to enable us to provide our services if you choose not to
        provide it we may not be able to continue to advise you. We may use
        software to evidence your identity. We would not require your consent
        for electronic ID checks but will inform you, on request how such
        software operates and the purpose for which it is used.
      </p>

      <p>
        From time to time we may record both the video and audio meetings that
        we have. The reason for recording such meetings would be to use the
        information gathered in them as part of our information gathering
        process with regards to your circumstances and objectives and to help
        ensure the accuracy of the meeting minutes. The recordings will be
        stored for no longer than is necessary without a lawful reason for doing
        so and will then be securely destroyed.
      </p>

      <p>
        Where we obtain your data otherwise than directly from you, you will
        have the same or equivalent rights to those set out in this Notice.
      </p>

      <h2 id="howisyourinformationused">How is your information used?</h2>

      <p>We will use your information to:</p>

      <ol>
        <li>Act as the basis for any advice we provide</li>

        <li>
          To carry out our obligations arising from any contracts entered into
          by you and us;
        </li>

        <li>
          Provide information to investment providers or life assurance firms
          for the purposes of arranging products and services for you;
        </li>

        <li>Provide our ongoing service to you.</li>

        <li>
          Meet our regulatory obligations in the services we provide to you.
        </li>

        <li>
          To provide audit trail and records for the purposes of assessing
          claims.
        </li>
      </ol>

      <h2 id="whatisthelawfulbasisonwhichwewillholdyourdata">
        What is the lawful basis on which we will hold your data?
      </h2>

      <p>
        When we speak with you about your investment and insurance requirements
        we do so on the basis that both parties are entering a contract for the
        supply of services. We have the right to use your personal data in order
        to perform that contract, and to arrange the products or services that
        you require.
      </p>

      <p>
        Alternatively, eitherin the course ofinitialdiscussions with you or when
        the contract between us hascome to an end, we have the right to use your
        personal data provided it is in our legitimate business interest to do
        so and your rights are not affected. For example, we may need to respond
        to requests from product providers, insurance providers orthird
        partyservice providers, compliance providers relating to the advice we
        have given to you, or tomake contact withyou to seek feedback on the
        service you received.
      </p>

      <p>
        We will use your personal data tocomply withregulatory obligations
        imposed by the Financial Conduct Authorityin regard tothe relevant 'Know
        Your Client' obligations. In addition, tocomply withthe Regulator's
        requirements for record keeping for the purposes of audits and reviews,
        records of transactions undertaken, customer histories, or for any other
        wider compliance with any legal or regulatory obligation to which we
        might be subject.
      </p>

      <p>
        To respond to any legitimate legal requests for information about you
        for example to the Regulatory authority, HMRC, orpursuant toan order of
        any court or tribunal having relevantjurisdiction, or as required by law
        for the purposes of but not limited to combatting fraud,
        money-laundering and criminal activities.
      </p>

      <p>
        To carry out our legitimate business and professional management
        responsibilities which include but are not limited to providing you with
        suitable advice, ensuring your portfolio and financial products continue
        to be suitable for you, adhere to anti money laundering requirements and
        investigating and resolving complaints.
      </p>

      <h2 id="thebasisuponwhichwewillprocesscertainpartsofyourpersonaldata">
        The basis upon which we will process certain parts of Your Personal Data
      </h2>

      <p>
        Where you ask us to assist you with for example your insurance needs, in
        particular life insurance and insurance that may assist you in the event
        of an accident or illness, we may ask you information about your ethnic
        origin, your health and medical history (special data). We will record
        and use this special data to make enquiries of insurance providers in
        relation to insurance products that may meet your needs and to provide
        you with advice regarding the suitability of any product that may be
        available to you.
      </p>

      <p>
        If you have parental responsibility for children under the age of 13, it
        is also very likely that we will record information on our systems that
        relates to those children and potentially, to their Special Data.
      </p>

      <p>
        The arrangement of certain types of insurance may involve disclosure by
        you to us of information relating to historic or current criminal
        convictions or offences (together "<strong>Criminal Disclosures</strong>
        "). This is relevant to insurance related activities such as
        underwriting, claims and fraud management.
      </p>

      <p>
        We will use Special Data and any Criminal Disclosures in the same way as
        Your Personal Data generally, as set out in this Privacy Policy.
      </p>

      <p>
        Information on Special Category Data and Criminal Disclosures must be
        capable of being exchanged freely between insurance intermediaries such
        as our Firm, and insurance providers, to enable customers to secure the
        important insurance protection that their needs require.
      </p>

      <h2 id="whathappenstoyourpersonaldatawhenitisdisclosedtous">
        What happens to Your Personal Data when it is disclosed to us?
      </h2>

      <p>In the course of handling your Personal Data, we will:</p>

      <ul>
        <li>
          • Record and store your Personal Data in our paper files, mobile
          devices and on our computer systems (e-mail, hard drives, online
          portals and cloud facilities). This information can only be accessed
          by employees and advisers within{" "}
          <strong>
            Cutter & Co Financial Planning Ltd and its parent company (TWPS)
          </strong>{" "}
          and not by other companies directly within our group structure except
          when it is necessary to do so to provide our service to you or to
          perform any administration tasks associated with or incidental to that
          service.
        </li>

        <li>
          Submit your Personal Data to Product Providers and Insurance Product
          providers, both in paper form and on-line via a secure portal or
          encrypted messaging. The provision of this information to a third
          party is essential in allowing us to progress any enquiry or
          application made on your behalf and to deal with any additional
          questions or administrative issues providers may raise.
        </li>

        <li>
          Use your Personal Data for the purposes of responding to any queries
          you may have in relation to any investment product or insurance policy
          you may take out, or to inform you of any developments in relation to
          those products and/or polices of which we might become aware.
        </li>
      </ul>

      <h2 id="howlongisinformationretained">
        How long is information retained?
      </h2>

      <p>
        We are required by legislation and the Financial Conduct Authority rules
        to retain records for specified periods. These vary dependent upon the
        nature of the service provided. We will therefore hold Data that is
        required to be held for the purposes of any such Regulator at least
        until the end of any specified limitation period imposed by that
        Regulator. By way of example, where we are actively managing your
        investments, we will hold Data in line with Financial Conduct Authority
        requirements for a period of not less than 6 years following the
        cessation of our agreement in relation to those investments.
        Importantly, we will not retain information for longer than is necessary
        e.g. for longer than the period that is laid down, without a lawful
        reason for doing so.
      </p>

      <h2 id="whohasaccesstoyourinformation">
        Who has access to your information?
      </h2>

      <p>
        We will not sell or rent your information to third parties. We will not
        share your information with third parties for marketing purposes. From
        time to time your Personal Data will be shared with:
      </p>

      <p>
        <ul>
          <li>
            • Other companies with our group structure and only when it is
            appropriate to provide our services to you. For details on the
            Privacy Notices of our other companies within our group please
            contact us on the address above and we will be pleased to assist
            you.
          </li>
          <li>
            Household member(s) you choose to link your data with on online
            portals e.g. the Personal Finance Portal (where applicable).
          </li>
          <li>
            Investment, insurance and other financial service product providers.
          </li>

          <li>
            • Third parties to assist us in the delivery of our services to you
            e.g. to assist us with your enquiry or applications, or who are able
            to support your needs as identified. These third parties may include
            but are not limited to, our compliance advisers, paraplanning
            support providers, product specialists, investment firms, third
            party research/ analysis firms, estate agents, providers of legal,
            accountancy, IT and other back office support services.
          </li>
        </ul>
      </p>

      <p>
        In each case, your Personal Data will only be shared for the purposes
        set out in this privacy notice, e.g. to progress your or our investment,
        insurance and other enquires and/or to provide you with our professional
        services or to comply with any legal, regulatory or other legislative
        requirement.
      </p>

      <p>
        Please note that this sharing of Your Personal Data does not entitle
        such third parties to send you marketing or promotional messages: it is
        shared to ensure we can adequately fulfil our responsibilities to you,
        and as otherwise set out in this Privacy Policy.
      </p>

      <h2 id="yourrightsinrelationtoyourinformation">
        Your rights in relation to your information
      </h2>

      <p>
        The accuracy of your information is important to us. Where we provide
        just an initial advice service the information will reflect your
        situation at that time and we will not normally update this (apart from
        a change of contact information). Where we are providing an ongoing
        service we will update the information as appropriate when we undertake
        a review with you. If between reviews you change your contact
        information please notify us and we will update our records. According
        to our policies and relevant legislation, you have a number of specific
        rights, as summarised below:
      </p>

 

      <p>
        <strong>Access</strong> - You may ask for a copy of the information{" "}
        <strong>Cutter &amp; Financial Planning Ltd</strong> hold about you and
        we will provide this within one month of receipt free of charge (we may
        charge a fee for subsequent or duplicate requests).
      </p>

      <p>
        <strong>Rectification</strong>- You may ask us to correct any
        information that we hold that is inaccurate or incomplete.
      </p>

      <p>
        <strong>Erasure</strong> - You may ask us to delete or cease processing
        data in certain situations. Please note that we will have regulatory
        obligations to retain information for certain time periods and we will
        retain such information as we believe is necessary to fulfil these
        obligations.
      </p>

      <p>
        <strong>Restrict processing</strong> - You may ask us to cease
        processing information. This means that we will be able to retain it but
        no longer act upon it. In the event that you no longer need our services
        and terminate them we will automatically cease processing information.
      </p>

      <p>
        <strong>Portability</strong> - You may have the right to have your data
        transferred to another service provider in an appropriate electronic
        format. Please note that we will have regulatory obligations to retain
        copies of the information as outlined previously.
      </p>

      <p>
        <strong>Objection</strong> - You may have the right to object to us
        processing information or using it for marketing purposes.
      </p>

      <p>
        This is a brief summary of your rights and there may be restrictions on
        some of them. If you wish to explore any of these rights at any time
        please contact us on the address above and we will be pleased to assist
        you.
      </p>

      <h2 id="securityprecautionsinplacetoprotectthelossmisuseoralterationofyourinformation">
        Security precautions in place to protect the loss, misuse or alteration
        of your information
      </h2>

      <p>
        When you give us personal information, we take steps to ensure that it
        is retained securely and processed in a confidential manner. Your
        information may be accessed by your adviser and our support staff for
        the purposes of providing our services to you. In addition, it may be
        accessed by senior managers and our compliance consultants (or the FCA)
        for the purposes of ensuring compliance with our regulatory obligations
        and reviewing the quality of our advice.
      </p>

      <p>
        Information may be transmitted normally over the Internet, and this can
        never be guaranteed to be 100% secure. As a result, while we strive to
        protect your personal information, we cannot guarantee the security of
        any information that passes between us, and you should consider the risk
        of this. Once we receive your information, we make our best effort to
        ensure its security on our systems. Where we have given (or where you
        have chosen) a password which enables you to access certain parts of our
        websites, you are responsible for keeping this password confidential. We
        ask you not to share your password with anyone.
      </p>

      <p>
        We also expect you to take reasonable steps to safeguard your own
        privacy when transferring information to us, such as not sending
        confidential information over unprotected e-mail, ensuring e-mail
        attachments are password protected or encrypted and only using secure
        methods of postage when original documentation is being sent to us.
      </p>

      <h2 id="cookies">Use of 'cookies'</h2>

      <p>
        Like many other websites, our website uses cookies. We may use cookies
        to provide the services, features and functionality offered on our
        website and to improve our user experience. Cookies are small files or
        other pieces of data which are downloaded or stored on your computer or
        other device, that can be tied to information about your use of our
        website (including any third party services and features offered as part
        of our website).
      </p>

      <p>
        By using our website you agree to such use of cookies unless you later
        decide to disable them. Please note that if you delete or disable our
        cookies you may experience interruptions in or limitations of
        functionality in certain areas of the website.
      </p>

      <h2 id="linkstootherwebsites">Links to other websites</h2>

      <p>
        Our website may contain links to other websites run by other
        organisations. This privacy policy applies only to our website, so we
        encourage you to read the privacy statements on the other websites you
        visit. We cannot be responsible for the privacy policies and practices
        of other sites even if you access them using links from our website.
      </p>

      <p>
        In addition, if you linked to our website from a third party site, we
        cannot be responsible for the privacy policies and practices of the
        owners and operators of that third party site and recommend that you
        check the policy of that third party.
      </p>

      <h2 id="transferringyourinformationoutsideofeurope">
        Transferring your information outside of Europe
      </h2>

      <p>
      As part of the services offered to you the information which you provide to us may be transferred to countries outside the European Union (“EU”). By way of example, this may happen if any of our servers are from time to time located in a country outside of the EU. These countries may not have similar data protection laws to the UK. By submitting your personal data, you’re agreeing to this transfer, storing or processing. If we transfer your information outside of the EU in this way, we will take steps to ensure that appropriate security measures are taken with the aim of ensuring that your privacy rights continue to be protected as outlined in this Privacy Notice.
      </p>

      <h2 id="contactingyou">Contacting you</h2>

      <p>
        We will make appropriate contact with you to provide the agreed
        services. Where this includes our ongoing service we will contact you at
        the agreed intervals to undertake our review. We may also contact you in
        between the agreed intervals if we believe that you need to take action
        (e.g. if you should consider making ISA or pension contributions before
        tax year end) or be aware of changes in the economic situation.
      </p>

      <p>
        We will not contact you for marketing purposes by post, e-mail, phone or
        text message unless you have expressly given us your prior consent. You
        may withdraw your consent to marketing at any time and the use of your
        Personal Data as described in this privacy notice is not affected by
        whether you choose to consent to the use of Data for marketing purposes.
        You can withdraw your consent or change your marketing preferences at
        any time by contacting us by e-mail: datacontroller@truewg.com or post.
      </p>

      <p>
        If we feel we have a legal right not to deal with your request, or to
        action, it in different way to how you have requested, we will inform
        you of this at the time.
      </p>

      <p>
        You should also make contact with us as soon as possible on you becoming
        aware of any unauthorised disclosure of your Personal Data, so that we
        may investigate and fulfil our own regulatory obligations.
      </p>

      <h2 id="righttocomplain">Right to complain</h2>

      <p>
        If you believe that we are not holding your information correctly or are
        unhappy at any dealings with us regarding your information you may
        complain to the Information Commissioners Office. You can do this via
        their website https://ico.org.uk/concerns or by calling 0303 123 1113.
      </p>

      <h2 id="reviewofthispolicy">Review of this Policy</h2>

      <p>
        We keep this Policy under regular review, and it was last updated in
        June 2023.
      </p>
    </Section>
  </Layout>
)

export default PrivacyPolicyPage

export const pageQuery = graphql`
  query {
    privacyPolicyImage: file(relativePath: { eq: "privacy-policy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
